import React, { useEffect, useState } from "react";

import { graphql } from "gatsby";
// import { StaticImage } from "gatsby-plugin-image";
// import KnowMoreSection from "../components/KnowMoreSection";
import { Seo } from "../components/seo";
import Faq from "../components/Faq";
import Layout from "../components/layout";
const initialQuestions = [];
const initialFaqData = [];

export default function ResourceDetail({ data }) {
  const resource = data.allWpResource.edges[0].node;
  // const imageLists = resource.resources.addImagesForGatsby;

  const [questions, setQuestions] = useState(initialQuestions);
  const [faqData, setFaqData] = useState(initialFaqData);

  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(resource.resources.faq, "text/html");
    const h3Elements = doc.querySelectorAll("h3");
    const pElements = doc.querySelectorAll("p");

    // Extract text content from <h3> elements and add to the questions array
    const extractedQuestions = Array.from(h3Elements).map((element) =>
      element.textContent.trim()
    );

    // Extract text content from <p> elements and add to the faqData array
    const extractedFaqData = Array.from(pElements).map((element) => ({
      para: [element.textContent.trim()],
    }));

    // Set the state with the extracted data
    setQuestions(extractedQuestions);
    setFaqData(extractedFaqData);
  }, [resource.resources.faq]);
  const faqDes = ``;
  
  return (
    <>
      <Seo title={resource.seo.title} description={resource.seo.metaDesc} />
      <Layout>
        <section className="lg:pt-[120px] md:pt-[100px] pt-[80px] relative">
          <div className="container">
            <div className="lg:mb-[100px] mb-[50px]">
              <h1 className="lg:text-[95px] relative md:text-[80px] text-[50px] leading-[1.2] tracking-[.08em] md:leading-[1.3684] mb-[20px] lg:mb-[30px] font-heading font-medium">
                {resource.title}
              </h1>
              <p className="lg:text-[18px] md:text-[16px] text-[15px] tracking-[.03em] leading-[1.733] md:leading-[1.875] lg:leading-[1.67] font-body max-w-[800px] lg:max-w-[800px]">
                {resource.resources.shortDescription}
              </p>
            </div>
            <div className="flex lg:gap-[70px] md:gap-[50px] gap-[40px] flex-col-reverse lg:flex-row">
              <div className="flex flex-col gap-[50px]">
                {resource.resources.image1 && (
                  <img
                    src={resource.resources.image1.sourceUrl}
                    alt={`resources`}
                  />
                )}
                {resource.resources.image2 && (
                  <img
                    src={resource.resources.image2.sourceUrl}
                    alt={`resources`}
                  />
                )}
                {resource.resources.image3 && (
                  <img
                    src={resource.resources.image3.sourceUrl}
                    alt={`resources`}
                  />
                )}
                {resource.resources.image4 && (
                  <img
                    src={resource.resources.image4.sourceUrl}
                    alt={`resources`}
                  />
                )}
                {resource.resources.image5 && (
                  <img
                    src={resource.resources.image5.sourceUrl}
                    alt={`resources`}
                  />
                )}
                {resource.resources.image6 && (
                  <img
                    src={resource.resources.image6.sourceUrl}
                    alt={`resources`}
                  />
                )}
                {resource.resources.image7 && (
                  <img
                    src={resource.resources.image7.sourceUrl}
                    alt={`resources`}
                  />
                )}
                {resource.resources.image8 && (
                  <img
                    src={resource.resources.image8.sourceUrl}
                    alt={`resources`}
                  />
                )}
              </div>
              <div className="flex flex-col gap-[30px] resource lg:w-[400px]">
                <div
                  className="resources-content"
                  dangerouslySetInnerHTML={{
                    __html: resource.resources.description,
                  }}
                ></div>
                <a
                  href={resource.resources.gumroadLink}
                  className="px-[50px]  lg:w-full text-center rounded-[3px] font-medium bg-[#53D4B5] hover:bg-[#43C5A5] transition-all duration-300 hover:transition-all hover:duration-300 lg:text-[18px] md:text-[16px] text-[15px] tracking-[.03em] leading-[1.733] md:leading-[1.875] lg:leading-[1.67]"
                >
                  Download For Free
                </a>
              </div>
            </div>
          </div>
        </section>

        <Faq Questions={questions} section des={faqDes} link faqData={faqData} />
        {/* <KnowMoreSection Include="Home" /> */}
      </Layout>
    </>
  );
}

export const query = graphql`
  query ($slug: String!) {
    allWpResource(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title
          seo {
            metaDesc
            title
          }
          resources {
            faq
            description
            shortDescription
            gumroadLink
            image1 {
              sourceUrl
            }
            image2 {
              sourceUrl
            }
            image3 {
              sourceUrl
            }
            image4 {
              sourceUrl
            }
            image5 {
              sourceUrl
            }
            image6 {
              sourceUrl
            }
            image7 {
              sourceUrl
            }
            image8 {
              sourceUrl
            }
          }
        }
      }
    }
  }
`;
